import styled from 'styled-components';
// import {device} from '../../../theme'

export const BackgroundForMenu = styled.div `
  padding: 50px;
  background-color: black;
`;
export const PrivacyWrapper = styled.div `
  padding: 20px;
`;
