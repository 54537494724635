import React from "react";
import SEO from "../components/seo";
import Layout from "../containers/layout/layout";
import Privacy from "../components/privacy"

export default () => (
  <Layout>
    <SEO
      title="Маркетинговое агентство полного цикла ostrovsky-marketing"
      description={`Любим свою работу, постоянно развиваемся сами и помогаем 
                развиваться вам. Творческий подход и оценимый эффект.`}
    />
    <Privacy/>
  </Layout>
);
